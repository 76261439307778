/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style, SEO and settings
import Helmet from 'react-helmet';
import {
  Col,
  css,
  Grid,
  Row,
  up,
  down,
  styled,
  thd,
  withTheme,
} from '@smooth-ui/core-sc';

// Additional Components/Containers
import Button from '_platform/src/components/Button/Button';
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import SvgIcon from '../../components/CustomComponents/SvgIcon/SvgIcon';

import BgDesktop from './images/HP-LoginImage-@1x.png';
import BgDesktop2x from './images/HP-LoginImage-@2x.png';
import BgMobile from './images/HP-LoginImage-Mobile-@1x.png';
import BgMobile2x from './images/HP-LoginImage-Mobile-@2x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.section`
  align-items: center;
  background-color: ${thd('black', '#0d0d0d')};
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: flex-start;

  ${up(
    'lg',
    css`
      flex-direction: column;
      justify-content: center;
      flex-basis: 200px;
    `
  )};

  ${up(
    'lg',
    css`
      background-image: url('${BgDesktop}');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top right;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
          background-image: url('${BgDesktop2x}');
      }
    `
  )};

  padding: 10px 0;

  .page-container {
    ${up(
      'md',
      css`
        margin-left: 10px;
      `
    )};
  }
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  ${up(
    'lg',
    css`
      padding-bottom: 1rem;
    `
  )};

  img {
    height: 72px;
    height: auto;
    width: 192px;
  }
`;

const IntroContainer = styled.div`
  background-color: rgba(35, 31, 32, 0.85);
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  ${up(
    'lg',
    css`
      padding: 2rem;
    `
  )};

  h2 {
    font-size: 30px;
  }
  p {
    color: #fff;
  }
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
    &:visited {
      color: #000;
    }
  }

  .content-row {
    margin: 0;
    padding: 0;
    ${down(
      'md',
      css`
      background-image: url('${BgMobile}');
      background-repeat: no-repeat;
      background-position: top left;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
          background-image: url('${BgMobile2x}');
      }
      `
    )};
  }
`;

const LoginContainer = styled.div`
  max-width: 500px;

  button {
    min-width: 175px;
  }

  .reset-password {
    a {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
    }
  }

  ${up(
    'lg',
    css`
      padding-left: 2rem;
      min-width: 300px;
    `
  )};
`;

const RegisterButton = styled(Button)`
  background-color: ${thd('primary', '#D19C45')};
  color: #000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  min-width: 225px;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  text-align: center;
  width: 100%;

  ${up(
    'md',
    css`
      width: 44%;
    `
  )};

  &.jbhifi {
    min-width: 160px;

    ${up(
      'md',
      css`
        float: right;
      `
    )};
  }
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Grid>
            <Row justifyContent="center">
              <Col>
                <LoginLogo className="text--center">
                  <SvgIcon
                    icon="footer-logo"
                    scaleWidth="64"
                    scaleHeight="24"
                    width="192px"
                    height="72px"
                    fill="#9B9C9B"
                    title="Hewlett Packard Platinum Club"
                  />
                </LoginLogo>
              </Col>
            </Row>
            <Row className="content-row">
              <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                <h2>
                  <strong>Welcome</strong> to the{' '}
                  <span>
                    <strong>HP Platinum Club</strong>
                  </span>
                </h2>
                <p className="spacer--bottom--small">
                  The HP Platinum Club is designed to reward you for your sales
                  of selected HP products and for completing training. The more
                  products you sell, the more points you earn and the more
                  rewards you receive, plus complete training to earn even more
                  points.
                </p>
                <p className="spacer--bottom--small">
                  Register below to participate in the HP Platinum Club.
                </p>
                <RegisterButton
                  as={Link}
                  className="harveynorman"
                  to="/register/harveynorman"
                >
                  <span className="text--large">Harvey Norman</span>
                  <br />
                  Registration
                </RegisterButton>
                <RegisterButton
                  as={Link}
                  className="jbhifi"
                  to="/register/jbhifi"
                >
                  <span className="text--large">JB Hi-Fi</span>
                  <br />
                  Registration
                </RegisterButton>
              </Col>
              <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                <LoginContainer>
                  <h2>
                    <strong>Already registered?</strong>
                  </h2>
                  <LoginProvider disableMeta>
                    <LoginForm formLabels={{ labelUserName: 'Username' }} />
                  </LoginProvider>
                </LoginContainer>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
